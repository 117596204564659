import ConstantsBasedTextInput from "../ConstantsBasedTextInput/ConstantsBasedTextInput";
import ConstantsBasedPullDownMenu from "../ConstantsBasedPulDownMenu/ConstantsBasedPullDownMenu";
import MultiLineTableElement from "../MultiLineTableElement/MultiLineTableElement";
import SelectDeclarationInput from "../SelectDeclarationInput";
import PhoneNumberInput from "../PhoneNumberInput";
import { isArray, isUndefined } from "lodash";
import { useMemo } from "react";
import ReadOnlyElement from "../ReadOnlyElement/ReadOnlyElement";

/**
 * ValidationInput is used as a wrapper around custom inputs of all types
 * Used by the InputsSection below and any other component that utilizes the custom inputs, such as SettingsLayout
 */
const ValidationInput = (props) => {
	const { onChange, value, element, groupingElement, inputStyle, readOnly, error } = props;

	/**
	 * groupingElement will only be passed for declaration inputs,
	 * elements coming from Settings and other layouts will not have this prop applied, so type is extracted from the element directly
	 */
	const type = useMemo(
		() => groupingElement?.type || element?.type || (element.pullDownMenu ? "pulldown" : "text"),
		[groupingElement, element]
	);

	const readOnlyValue = useMemo(() => {
		if (!readOnly) {
			return;
		} else if (isUndefined(value) || (isArray(value) && !value.length)) {
			return "Not specified";
		} else if (element?.pullDownMenu) {
			const pullDownOption = element.pullDownMenu.find((option) => option.key === value);

			if (pullDownOption) {
				return `${pullDownOption.value}, ${pullDownOption.key}`;
			} else {
				return value;
			}
		} else {
			return value;
		}
	}, [value, readOnly]);

	let Input = <></>;

	if (type === "declarationSelection") {
		return <SelectDeclarationInput onChange={onChange} value={value} />;
	}

	if (!element) {
		return null;
	}

	if (readOnly) {
		return (
			<ReadOnlyElement
				element={element}
				style={{ width: "100%", paddingBottom: "0px", marginBottom: "0px" }}
				data={readOnlyValue}
			/>
		);
	}

	if (type === "text" || type === "datePicker") {
		Input = (
			<ConstantsBasedTextInput
				inputStyle={{ ...groupingElement?.inputStyle, ...inputStyle }}
				onChange={onChange}
				value={value}
				element={element}
				dateInput={type === "datePicker"}
				error={error}
			/>
		);
	} else if (type === "pulldown") {
		Input = (
			<ConstantsBasedPullDownMenu
				controlled
				showResetButton={!element.hideResetButton}
				inputStyle={{
					...groupingElement?.inputStyle,
					...inputStyle,
				}}
				onChange={onChange}
				value={value}
				element={element}
				error={error}
			/>
		);
	} else if (type === "multiline") {
		Input = (
			<MultiLineTableElement
				element={element}
				groupingElement={groupingElement}
				onChange={onChange}
				value={value}
				error={error}
			/>
		);
	} else if (type === "phoneNumber") {
		Input = <PhoneNumberInput element={element} onChange={onChange} value={value} readOnly={readOnly} />;
	}

	// TODO: Move element exists warning to InputsSection
	return (
		<>
			{Input}
			{/* {headerOrItemElements.includes(element.elementName) ? (
				<div className={withTheme("warning-text")}>
					This element exists on both header and item levels, but can only have a value on
					one of both levels
				</div>
			) : (
				<></>
			)} */}
		</>
	);
};

export default ValidationInput;
