import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import DeclarationsContext from "../../../../context/declarations/DeclarationsContext";
import ThemeContext from "../../../../context/theme/ThemeContext";
import { updateQueryParam } from "../../../../router/utils";
import NotificationToast from "../../../common/NotificationToast";
import CancelDeclarationModal from "../../../modals/CancelDeclarationModal";
import Button from "../../../common/Button";
import { PiArrowFatUp, PiPencilSimple, PiX } from "react-icons/pi";
import { DECLARATION_ID_QUERY_PARAM, DECLARATION_STATUSES } from "../../../../constants/GlobalConstants";
import DeclarationActionsPopover from "./ActionsPopover";
import ConfirmationModal from "../../../modals/ConfirmationModal";

const DeclarationActions = (props) => {
	const {
		state,
		submitDeclaration,
		getValidationErrors,
		setHasUnsavedChanges,
		updateDeclaration,
		createAmendment,
	} = useContext(DeclarationsContext);
	const [isCancelConfirmModalOpen, setIsCancelConfirmModalOpen] = useState(false);
	const [isSubmitConfirmModalOpen, setIsSubmitConfirmModalOpen] = useState(false);
	const { withTheme } = useContext(ThemeContext);
	const { t } = useTranslation();
	const [toast, setToast] = useState({ text: "", variant: "" });
	const { setLoadingAction, loadingAction } = props;

	// TODO: Explain
	const handleAmendClick = async () => {
		const existingAmendment = state.declaration.relatedDeclarations.find(
			({ isAmendment, status }) => isAmendment && status === DECLARATION_STATUSES.INCOMPLETE
		);

		if (existingAmendment) {
			updateQueryParam(DECLARATION_ID_QUERY_PARAM, existingAmendment._id);
		} else {
			setLoadingAction("amend");

			const amendment = await createAmendment(state.declaration);
			if (amendment) {
				updateQueryParam(DECLARATION_ID_QUERY_PARAM, amendment._id);
			}

			setLoadingAction(null);
		}

		// TODO: Show toast explaining what's happened
	};

	const handleSubmitClick = async (confirmed) => {
		if (loadingAction) {
			return;
		}

		const errors = getValidationErrors();

		if (errors.length > 0) {
			return setToast(t("validationErrorToast"));
		}

		console.log(state.declaration.data);

		if (confirmed) {
			setLoadingAction("submit");

			if (state.hasUnsavedChanges) {
				await updateDeclaration(state.declaration._id, { data: state.declaration.data });
			}

			try {
				await submitDeclaration(state.declaration);
				setToast({ text: t("submitSuccessToast"), variant: "success" });
			} catch (e) {
				console.log(e);
			}

			setLoadingAction(null);
			setIsSubmitConfirmModalOpen(false);
			setHasUnsavedChanges(false);
		} else {
			setIsSubmitConfirmModalOpen(true);
		}
	};

	if (!state.declaration) {
		return null;
	}

	return (
		<div className={withTheme("declaration-action-buttons-container")}>
			<NotificationToast
				isOpen={Boolean(toast.text)}
				onClose={() => setToast({})}
				text={toast.text}
				variant={toast.variant}
			/>

			<CancelDeclarationModal
				isOpen={isCancelConfirmModalOpen}
				onClose={() => setIsCancelConfirmModalOpen(false)}
			/>

			<ConfirmationModal
				title={t("areYouSure")}
				description={t("submitConfirmation")}
				isOpen={isSubmitConfirmModalOpen}
				isActionLoading={loadingAction === "submit"}
				primaryActionLabel={t("submit")}
				onPrimaryActionClick={() => handleSubmitClick(true)}
				onClose={() => {
					if (!loadingAction) {
						setIsSubmitConfirmModalOpen(false);
					}
				}}
			/>

			{state.declaration.status === DECLARATION_STATUSES.ACCEPTED && (
				<>
					<>
						<Button
							type="secondary"
							onClick={() => setIsCancelConfirmModalOpen(true)}
							LeftIcon={PiX}
						>
							{t("cancelDeclaration")}
						</Button>

						<Button
							isLoading={loadingAction === "amend"}
							onClick={handleAmendClick}
							LeftIcon={PiPencilSimple}
						>
							{t("amend")}
						</Button>
					</>
				</>
			)}

			{(!state.declaration.status ||
				state.declaration.status === DECLARATION_STATUSES.INCOMPLETE) && (
				<Button onClick={() => handleSubmitClick()} LeftIcon={PiArrowFatUp}>
					{t("submit")}
				</Button>
			)}

			<DeclarationActionsPopover />
		</div>
	);
};

export default DeclarationActions;
